import translate from '../../i18n/translate'
import '../../App.css';
import mn from '../../assets/img/ipco.png'
import tk from '../../assets/img/web.png'
import evi from '../../assets/img/Nouveau projet.svg'
import styles from '../styles/device.module.css'
// import light_from_scy from "../../assets/new_images/light_from_scy.svg";
import light_from_scy from "../../assets/new_images/light_from_scy.svg";
// import noise from "../../assets/new_images/noise.svg";
import noise from "../../assets/new_images/noise.svg";
import arrow_up from "../../assets/new_images/arrow_up.svg";
import arrow_down from "../../assets/new_images/arrow_down.svg";
import discover from "../../assets/new_images/discover.svg";
import disk from "../../assets/new_images/disk.svg";
import event from "../../assets/new_images/event.svg";
import appStore from "../../assets/new_images/appStore.svg";
import playStore from "../../assets/new_images/playstore.svg";
import listening1 from "../../assets/new_images/listening1.svg";
import listening2 from "../../assets/new_images/listening2.svg";
import phone_in_hand from "../../assets/new_images/phone_in_hand.svg";
import playlist from "../../assets/new_images/playlist.svg";
import song1 from "../../assets/new_images/song1.svg";
import song2 from "../../assets/new_images/song2.svg";
import phone_in_hand_svg from "../../assets/new_images/phone_in_hand.svg"
import { HashLink as Linka } from 'react-router-hash-link';

const DeviceContainer = () => {

    const clicked = () => {
        console.log("clicked")
    }
    
    return (
        <div className={`device-grid-container ${styles.deviceContainer}`}>
            {/* <div className="fond"></div> */}
            <div className="fond2"></div>
            <div className="device-grid">
                <h2 className='hero-title'>{translate("value")}</h2>
                <h3 className='hero-subtitle'>{translate("covalue")}</h3>
                <div className="row">
                    <a href='https://www.store.colorfol.com' target='_blank' rel='noopener noreferrer'>
                        <button className="btn-disc"><span className="btntext">{translate("disc")}</span><img className="imgbtn" src={arrow_down} /></button>
                    </a>
                    <a href="download"><button onClick={clicked} className="btn-freedown"><span className="btntext">{translate("freedown")}</span><img className="imgbtn" src={arrow_up} /></button></a>
                </div>
            </div>
            <div className="botsect1">
                <img src={phone_in_hand_svg} alt="phone_in_hand" className="phone_in_hand" />
                <div>
                    <img src={discover} className="discover" />
                    <img src={disk} className="disk" />
                    <img src={playlist} className="playlist" />
                    <img src={song1} className="song1" />
                    <img src={song2} className="song2" />
                    <img src={listening1} className="listening1" />
                    <img src={listening2} className="listening2" />
                    <img src={event} className="event" />
                    <div className="icon">
                        <a href="https://apps.apple.com/fr/app/colorfol/id1603123058" target="_blank" rel='noopener noreferrer'>
                            <img src={appStore} className="appStore" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.dev.mobile.colortech&hl=fr" target="_blank" rel='noopener noreferrer'>
                            <img src={playStore} className="playStore" />
                        </a>
                    </div>
                </div>
            </div>
            {/*<div className={`playful rotating ${styles.deviceBoxContainer}`}></div>*/}
            {/*<img className={`playit rotating ${styles.deviceImageContainer}`} src={evi} alt=""/>*/}

        </div>
    )
}

export default DeviceContainer