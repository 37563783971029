import React, { useEffect, useState, useRef } from "react";
import "./../App.css";
import { I18nPropvider, LOCALES } from "../i18n";
import Navbar from "../layouts/components/Navbar";
import DeviceContainer from "../layouts/components/DeviceContainer";
import WhySectionComponent from "../layouts/components/WhySectionComponent";
import PremiumContainer from "../layouts/components/PremiumContainer";
import EventContainer from "../layouts/components/EventContainer";
import TarifContainer from "../layouts/components/TarifContainer";
import Footer from "../layouts/components/Footer";
import NavbarMobileMenu from "../layouts/components/NavbarMobileMenu";
import ReactGA from "react-ga";

function App() {
  // Set local state
  const [language, setLanguage] = useState(LOCALES.ENGLISH);
  const [languageList, setLanguageList] = useState([]);

  useEffect(() => {
    ReactGA.initialize("UA-142748287-1");

    ReactGA.pageview(window.location.pathname);
  }, []);

  // Set Language list
  useEffect(() => {
    setLanguageList([
      {
        code: "Français",
        value: LOCALES.FRANCAIS,
      },
      {
        code: "English",
        value: LOCALES.ENGLISH,
      },
      {
        code: "عرب",
        value: LOCALES.ARABE,
      },
    ]);
  }, []);

  // Update the language of the website
  const updateHotLanguage = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <I18nPropvider locale={language}>
      <section className="body">
        <Navbar />

        <NavbarMobileMenu />

        <DeviceContainer />

        <WhySectionComponent />

        <PremiumContainer />

        <EventContainer />

        {/*<TarifContainer />*/}

        <Footer
            language={language}
            languageList={languageList}
            updateHotLanguage={updateHotLanguage}
        />
      </section>
    </I18nPropvider>
  );
}

export default App;
