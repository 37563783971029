import React from 'react';
import styles from '../layouts/styles/cgu.module.css'

function CGUPremium() {

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.background_container}></div>
      <div className={styles.content_container}>
        <div className={styles.all_page}>
          <h1>Conditions d'utilisation et de vente du service COLORFOL Premium</h1>
          <h2>Article 1 - Remarques générales</h2>
          <p>
            Le présent document constitue les Conditions d'utilisation et de vente du Service Colorfol Premium, qui incluent
            le site Internet accessible à l'adresse https://www.colorfol.com (ci-après le « Site »), ainsi que ses
            ordinateurs, tablettes et applications mobiles (ci-après l’« Application »). Le Site et l'Application sont
            créés, développés et exploités par COLORFOL SA (ci-après « COLORFOL »), société française immatriculée sous le
            numéro 898 969 852, dont le siège social est situé 24 rue de Calais 75009 Paris – FRANCE.
          </p>
          <p>
            Les présentes conditions d'utilisation et de vente (ci-après les « Conditions ») régissent exclusivement le
            Service Colorfol Premium fourni par COLORFOL Cameroun (ci-après « COLORFOL »), Société à Responsabilité Limitée,
            au capital de 980.000 Francs CFA, dont le siège social est situé Immeuble SOCAR, Akwa, Douala Cameroun
            immatriculée au Registre du Commerce et du Crédit Mobilier de Douala, sous le numéro RC/DLN/2022/B/095.
          </p>
          <p>
            Les présentes CGU ont pour objet de définir la relation contractuelle et commerciale entre COLORFOL, d'une part,
            et tout abonné au Service Colorfol Premium (ci-après l'« Abonné » ou “Vous”), d'autre part.
          </p>
          <p>
            L'abonnement et l'accès au Service Colorfol Premium dépendent strictement du consentement préalable sans aucune
            restriction et sous réserve de l'ensemble des Conditions (y compris la Politique de Confidentialité).
          </p>
          <h2>Article 2 - Présentation du Service Colorfol Premium</h2>
          <p>
            Le Service Colorfol Premium est un service proposé et exploité par COLORFOL permettant, en acceptant les
            présentes Conditions, d'écouter en illimité des enregistrements sonores, visuels (ci-après les « Enregistrements
            ") et d'autres contenus musicaux et culturels en haute qualité audio (que celle rendue disponible via les autres
            services COLORFOL, le cas échéant) et sans aucune publicité, sur plusieurs appareils et en "Mode hors ligne"
            comme décrit ci-dessous.
          </p>
          <p>
            Le service Colorfol Premium comprend de la musique personnalisée, des listes de lecture et d'autres
            recommandations de contenu.
          </p>
          <p>
            Depuis le Site ou l'Application, et une fois abonné, l'Abonné a accès à toutes les fonctionnalités du Service
            Colorfol Premium.
          </p>
          <p>
            Les principales caractéristiques du Service Colorfol Premium sont les suivantes :
          </p>
          <ul>
            <li>
              Accès illimité, aux fins de téléchargement temporaire sur trois appareils autorisés (appareils non portables
              ou portables) de la partie Enregistrements du catalogue musical du Service Colorfol Premium, afin de les
              écouter ensuite hors ligne ;
            </li>
            <li>
              Aucune publicité ;
            </li>
            <li>
              Une haute qualité sonore.
            </li>
          </ul>
          <p>
            Le Service Colorfol Premium est accessible à l'Abonné jusqu'à la fin de son abonnement comme indiqué sur la page
            d'abonnement du Site au moment de cette souscription ou de la résiliation de son abonnement selon les Conditions
            ci-dessous.
          </p>
          <p>
            Le Service Colorfol Premium est accessible depuis un ordinateur personnel (PC ou Mac) en se connectant au Site
            ou depuis un appareil portable via l'Application qui doit être téléchargée par l'Abonné. Le Service Colorfol
            Premium est également accessible sans aucune connexion Internet, en cliquant sur le bouton « Mode hors ligne »
            de l'Application.
          </p>
          <p>
            Le téléchargement temporaire d'Enregistrements ou de playlists est possible grâce au bouton Mode Hors Ligne qui
            apparaît sur les pages de l'Application. Les titres téléchargés pourront alors être écoutés sans aucune
            connexion Internet, mais ne pourront toutefois être transférés ou gravés sur quelque support que ce soit.
          </p>
          <p>
            COLORFOL informe l'Abonné qu'elle n'est pas responsable des publicités affichées au sein des contenus fournis et
            hébergés par des tiers et qui pourraient éventuellement être intégrées et/ou référencées au sein du Site ou de
            l'Application.
          </p>
          <h2>Article 3 - Utilisation du Service Colorfol Premium</h2>
          <p>
            L'utilisation du Service Colorfol Premium nécessite une connexion Internet et un service Internet pour appareils
            portables. Ces connexions ne sont pas assurées par COLORFOL, l'Abonné doit donc au préalable souscrire à une
            offre Internet pour mobile pour pouvoir utiliser le Service Colorfol Premium.
          </p>
          <p>
            Pour accéder au Service Colorfol Premium, l’utilisateur doit souscrire à un abonnement en créant un compte
            utilisateur à partir du Site Colorfol ou de l’Application.......
          </p>
          <p>
            Le catalogue musical disponible, dans le cadre du Service Colorfol Premium, est lié aux contrats en vigueur avec
            les ayants droit et peut donc évoluer. COLORFOL ne peut garantir la disponibilité d'un titre ou d'un album
            déterminé ou d'un artiste ou d'un groupe dans le catalogue du Service Colorfol Premium. De plus, COLORFOL ne
            peut garantir qu'un titre, un album, un artiste ou un groupe déterminé dans le catalogue du Service Colorfol
            Premium sera disponible indéfiniment. COLORFOL ne pourra jamais être tenu responsable de la suppression de
            parties du contenu du catalogue proposé.
          </p>
          <p>
            En tant qu'Abonné, vous pouvez activer le Service Colorfol Premium sur un ordinateur personnel (PC ou Mac) et un
            appareil portable compatible enregistré auprès du Service Colorfol Premium. Le Service Colorfol Premium n'est
            accessible que depuis une seule connexion à la fois (ordinateur personnel ou appareil portable compatible
            enregistré). COLORFOL dispose des moyens techniques pour vérifier l'absence de plusieurs connexions simultanées
            sur un même compte et donc détecter les tentatives de connexions multiples.
          </p>
          <h2>Article 4 - Disponibilité et modification du Service Colorfol Premium</h2>
          <p>
            Le Service Colorfol Premium est accessible 24h/24 et 7j/7, dans la limite des Conditions Générales et des termes
            de l'article 10 ci-dessous.
          </p>
          <p>
            COLORFOL a le droit d’apporter toute modification ou amélioration au Service Colorfol Premium s’il le juge
            nécessaire. COLORFOL garantit néanmoins que cela n’altèrera pas la qualité ni ne modifiera substantiellement les
            fonctionnalités du Service Colorfol Premium.
          </p>
          <p>
            En outre, COLORFOL a le droit, sans préavis ni indemnité, de supprimer temporairement l'accès au Service
            Colorfol Premium si nécessaire afin d'assurer sa maintenance ou sa continuité. L'Abonné reconnaît que COLORFOL
            ne peut être tenu responsable et renonce à tout droit à indemnisation et/ou action contre COLORFOL à ce titre.
            Les interruptions temporaires du Service Colorfol Premium seront, dans la mesure du possible, notifiées sur le
            Site au moins 24 (vingt-quatre) heures avant qu'elles ne surviennent, sauf si elles sont urgentes. COLORFOL a
            également le droit, sans indemnité, d'arrêter définitivement le Service. Tout arrêt définitif du Service
            Colorfol Premium sera notifié via le Site ou via l'Application au moins 1 (un) mois avant qu'il ne survienne,
            l'Abonné ne sera redevable d'aucun paiement dû à compter de la date d'arrêt du Service Colorfol Premium.
          </p>
          <h2>Article 5 - Droit de rétractation – Durée – Renouvellement – Résiliation – Mise à niveau vers le Service
            Colorfol Premium</h2>
          <h3>Droit de rétractation</h3>
          <p>
            L'Abonné dispose d’un droit de rétractation dans un délai de quinze (15) jours à compter de la date de la
            souscription à l’abonnement. Toutefois, l’Abonné reconnaît et accepte expressément que la fourniture du Service
            Colorfol Premium débute au moment de la validation de l'abonnement. Il reconnaît et accepte qu'il perd en
            conséquence son droit de remboursement de l'abonnement. En conséquence, aucune demande de remboursement ne sera
            acceptée après cette validation.
          </p>
          <h3>Durée – Offres d’essais et de découverte</h3>
          <p>
            COLORFOL propose un abonnement mensuel (ou toute autre durée selon les offres faites sur le Site ou
            l'Application).
          </p>
          <p>
            Certaines périodes d'essai ou de découverte du Service Colorfol Premium de durée différente peuvent être
            proposées sur le Site ou l'Application, temporairement ou non. Sauf indication contraire, ces périodes d'essai
            et de découverte seront soumises aux présentes CGU et seront limitées à un seul abonnement (même adresse IP
            (Protocole Internet) et/ou même adresse email) quelle que soit l'offre d'essai ou de découverte concernée.
          </p>
          <h3>Renouvellement</h3>
          <p>
            Sauf résiliation par l'Abonné dans les conditions de l'article 5.4 et sauf en cas de mode de paiement non
            récurrent utilisé par l'Abonné, l'abonnement au Service est tacitement reconduit pour la même durée exacte que
            celle initiale. Ainsi, si l'Abonné souscrit à un abonnement mensuel, cet abonnement sera automatiquement
            renouvelé chaque mois.
          </p>
          <p>
            Certaines offres d'essai ou de découverte sont soumises à la fourniture des coordonnées de votre carte bancaire
            afin de bénéficier de cette offre. Sauf indication contraire sur le Site ou l'Application ou dans les CGU et
            sauf résiliation par l'Abonné dans le respect des dispositions de l'article 5.4, toute offre d'essai ou de
            découverte est automatiquement transformée en abonnement mensuel au prix indiqué dans l'offre ou, à défaut , au
            prix applicable à l'abonnement mensuel du Service Colorfol Premium.
          </p>
          <h3>Résiliation</h3>
          <p>
            Si l'abonnement a été souscrit directement depuis le Site ou l'Application, pour résilier son abonnement,
            l'Abonné doit se rendre sur son compte et cliquer sur « Résilier mon abonnement ». La résiliation sera effective
            à la fin de la période d'abonnement en cours, si elle est demandée au moins 48 (quarante-huit) heures à
            l'avance. Pour une période d'essai ou de découverte, la résiliation intervient à la date et à l'heure de
            résiliation telles qu'indiquées sur le compte de l'Abonné, sauf indication contraire sur le Site.
          </p>
          <h3>Mise à niveau vers le service Colorfol Premium</h3>
          <p>
            Dans le cas où l'Abonné passe du Service Colorfol Freemium au Service Colorfol Premium, la mise à niveau prendra
            effet immédiatement comme autorisé par COLORFOL. Désormais, COLORFOL facturera immédiatement à l'Abonné
            l'abonnement au Service Colorfol Premium nouvellement activé.
          </p>
          <h2>Article 6 - Conditions d’accès au Service Colorfol Premium</h2>
          <p>
            L'Abonné déclare être capable d'accepter les présentes CGU, ce qui signifie qu'il n'est pas soumis à une mesure
            de protection légale (telle qu'une tutelle légale).
          </p>
          <p>
            L'Abonné déclare qu'il réside dans un pays où le Service Colorfol Premium est disponible et qu'il est titulaire
            d'une carte bancaire émise par une banque du même pays.
          </p>
          <h2>Article 7 - Création d'un compte</h2>
          <p>
            Pour souscrire au Service Colorfol Premium, chaque Abonné doit :
          </p>
          <ul>
            <li>
              Créer un compte sur le Site ou télécharger et créer un compte sur l'Application ou, si l'Abonné est déjà
              inscrit, se connecter au Site ou à l'Application ;
            </li>
            <li>
              Fournir les informations demandées dans le formulaire d'inscription ;
            </li>
            <li>
              Donner son consentement aux CGU ;
            </li>
            <li>
              Payer le prix de son abonnement via l'un des systèmes de paiement proposés ;
            </li>
            <li>
              Confirmer son abonnement.
            </li>
          </ul>
          <p>
            L'Abonné s'engage à fournir des informations véridiques, exactes et sincères le concernant dans le cadre de son
            inscription sur le Site, l'Application et le Service Colorfol Premium.
          </p>
          <p>
            Une fois son inscription validée, COLORFOL enverra à l'Abonné un email de confirmation à l'adresse email
            indiquée lors de l'inscription.
          </p>
          <p>
            Sous réserve des autres conditions des CGU, l'abonnement au Service Colorfol Premium ne deviendra effectif qu'à
            l'envoi par COLORFOL de l'email de confirmation mentionné ci-dessus. COLORFOL recommande à l'Abonné de
            sauvegarder et/ou d'imprimer cet email.
          </p>
          <p>
            L'Abonné peut à tout moment modifier le mot de passe renseigné sur son compte en cliquant sur la rubrique « Mes
            informations ».
          </p>
          <p>
            L'Abonné s'engage à informer COLORFOL, sans délai, de toute modification ou mise à jour des informations
            fournies lors de sa souscription au Service Colorfol Premium et notamment de toute modification de son adresse
            email ou de ses coordonnées bancaires. L'Abonné peut à tout moment modifier ses informations sur son compte sur
            le Site en cliquant sur la rubrique « Mes informations ».
          </p>
          <p>
            L'Abonné doit informer immédiatement COLORFOL de toute perte ou utilisation non autorisée de son compte, de ses
            identifiants ou de son mot de passe. Les mots de passe et informations d'identification sont personnels et
            l'Abonné s'engage à ne pas les communiquer. En conséquence, l’Abonné est seul responsable de leur utilisation.
          </p>
          <p>
            L'Abonné est seul responsable des conséquences dues à une mauvaise utilisation de son compte par l'Abonné
            lui-même ou par un tiers ayant accédé au compte de l'Abonné du fait de la faute ou de la négligence de l'Abonné
            et, en conséquence, l'Abonné reconnaît que COLORFOL et tous ses partenaires, cocontractants ou ayants droit ne
            pourront être tenus responsables à ce titre.
          </p>
          <h2>Article 8 - Prix</h2>
          <p>
            Le prix de l'abonnement est indiqué sur le Site ou dans l'Application et s'entend toutes taxes comprises.
          </p>
          <p>
            COLORFOL a le droit de modifier le prix de chaque offre d'abonnement de temps à autre. COLORFOL informera chaque
            Abonné par courrier électronique à l'adresse fournie par l'Abonné au moment de la souscription au Service
            Colorfol Premium (ou telle que mise à jour ultérieurement dans son compte) de toute augmentation de prix au
            moins 15 (quinze) jours avant qu'elle ne devienne effective. Dans le cas où l'Abonné n'accepterait pas cette
            augmentation, il pourra résilier son abonnement, comme prévu à l'article 5.4 ci-dessus, cette résiliation sera
            effective à l'échéance mensuelle de l'abonnement suivant. Si l’Abonnement n’est pas résilié, le nouveau prix
            sera applicable à l’Abonné à compter de la date d’échéance mensuelle de l’abonnement suivante.
          </p>
          <p>
            Toute augmentation de la TVA (Taxe sur la Valeur Ajoutée) sera automatiquement et immédiatement impactée sur le
            prix du Service Colorfol Premium. Il en sera de même en cas de création d'une nouvelle taxe qui sera basée sur
            le prix du Service Colorfol Premium et applicable à COLORFOL. Les conditions de résiliation applicables en cas
            de modification du prix du Service Colorfol Premium mentionnées ci-dessus, s'appliqueront dans les mêmes
            conditions en cas de modification de prix résultant de l'augmentation ou de la création de nouvelles taxes.
          </p>
          <p>
            Il est rappelé que les frais de connexion et de communication (Internet) liés à l'utilisation du Service
            Colorfol Premium ne sont pas pris en charge par COLORFOL et doivent être supportés uniquement par l'Abonné.
          </p>
          <h2>Article 9 - Paiement du prix</h2>
          <p>
            Différents modes de paiement sont proposés sur le Site (cartes de crédit, cartes prépayées, PayPal et/ou autres
            moyens de paiement).
          </p>
          <p>
            En fonction de la durée d'abonnement choisie par l'Abonné, le prix de l'Abonnement est payé mensuellement ou
            annuellement. Le premier paiement sera effectué au moment de la souscription puis au début de chaque mois ou
            année, par prélèvement automatique, jusqu'à la résiliation de l'abonnement, que la résiliation vienne de
            l'Abonné ou de COLORFOL.
          </p>
          <p>
            Afin d'optimiser la sécurité des transactions, COLORFOL a choisi les systèmes de paiement de différentes
            sociétés avec lesquelles il a conclu des accords. Les garanties apportées par COLORFOL en termes de sécurité des
            transactions sont identiques à celles obtenues par COLORFOL auprès de ces prestataires de services de paiement.
          </p>
          <p>
            Sauf preuve contraire, les registres informatisés, conservés dans les systèmes informatiques de COLORFOL et de
            ses partenaires dans des conditions raisonnables de sécurité, seront considérés comme les preuves des
            communications, commandes, validations et paiements intervenus entre l'Abonné et COLORFOL. Ces informations
            seront considérées comme une preuve valable entre l'Abonné et COLORFOL sauf si l'Abonné peut fournir une preuve
            écrite contradictoire.
          </p>
          <h2>Article 10 - Responsabilité de l'Abonné</h2>
          <p>
            L'Abonné est seul responsable des messages, contenus ou informations publiés par lui-même sur le Site ou
            l'application; COLORFOL est uniquement considéré comme un hébergeur de services Internet et ne peut être tenu
            pour responsable du contenu publié par les utilisateurs sur le Site ou l'application de bureau et sur lequel
            COLORFOL n'a aucun contrôle ou supervision.
          </p>
          <p>
            L'Abonné s'engage expressément, afin de dégager sa responsabilité, à ce qu'il veille à ce que les messages qu'il
            publie sur le Site ou l'application de bureau ne comportent pas notamment mais sans s'y limiter :
          </p>
          <ul>
            <li>
              Constituer une violation des droits de propriété intellectuelle de tiers ; en conséquence, l'Abonné s'engage à
              ne pas publier, sur le Site ou l'application de bureau, de contenu protégé au titre du droit d'auteur, une
              marque déposée ou plus généralement tout contenu protégé par tout autre droit de propriété intellectuelle
              détenu par des tiers sans l'accord préalable du ou des propriétaires de lesdits droits ;
            </li>
            <li>
              Contenir tout virus informatique susceptible d'interrompre, de détruire ou d'affecter les fonctionnalités du
              Site ou de l'application de bureau ;
            </li>
            <li>
              Faire l’apologie des délits ou contenir des messages illégaux ou menaçants ou à caractère pédophile,
              pornographique, diffamatoire, obscène, haineux, raciste, antisémite, xénophobe, révisionniste ou contraire à
              l'ordre public et aux bonnes mœurs ;
            </li>
            <li>
              Porter atteinte à un droit à la vie privée ou porter atteinte à la dignité de tiers ;
            </li>
            <li>
              Encourager la violence, le fanatisme, la criminalité, le suicide, la haine liée à la religion, à la race, au
              sexe, aux préférences sexuelles, à l'origine ethnique ;
            </li>
            <li>
              Harceler les autres Utilisateurs ;
            </li>
            <li>
              Promouvoir ou encourager toute activité ou entreprise criminelle ;
            </li>
            <li>
              Demander et/ou communiquer des mots de passe et/ou des informations personnelles à des fins commerciales ou
              illégales ;
            </li>
            <li>
              Transmettre des chaînes d'emails, des emails massifs non sollicités, des messages instantanés, des messages
              publicitaires et des spams ;
            </li>
            <li>
              Contenir de la publicité et/ou une sollicitation afin de proposer des produits et/ou services à vendre via le
              Site ou l'Application ;
            </li>
            <li>
              Contenir des adresses ou des liens Internet transférant vers un site Web externe dont le contenu enfreint
              toute loi et/ou réglementation applicable, enfreint les droits de tiers ou enfreint les Conditions.
            </li>
          </ul>
          <p>
            L'Abonné s'engage à ne pas utiliser de système automatique tel que des scripts dans le but d'ajouter des
            Utilisateurs à son compte Utilisateur et/ou d'envoyer des commentaires ou des messages.
          </p>
          <p>
            COLORFOL a le droit, en cas de violation par l'Abonné de toute loi ou de violation de tous droits de tiers, de
            fournir sur demande de toute autorité légitime (tribunaux, autorités administratives et forces de police), toute
            information permettant ou facilitant l'identification de l'Abonné.
          </p>
          <h2>Article 11 - Exclusion de garanties</h2>
          <p>
            L'Abonné déclare être informé des limites et limitations du réseau Internet.
          </p>
          <p>
            En conséquence, COLORFOL décline toute responsabilité quant à tout dysfonctionnement de l'accès au Service
            Colorfol Premium, de la vitesse d'ouverture et de consultation du Site ou des pages de l'Application, de la
            vitesse d'écoute des titres, de l'inaccessibilité temporaire ou définitive du Service Colorfol Premium, ainsi
            que du utilisation frauduleuse par des tiers des informations fournies sur le Site ou l'Application.
          </p>
          <p>
            Il appartient donc à l'Abonné de protéger son équipement notamment contre toute forme d'intrusion et/ou de
            contamination virale et dont COLORFOL ne pourra jamais être tenu pour responsable. COLORFOL ne pourra jamais
            être tenu responsable de tout dysfonctionnement ou dommage causé au matériel de l’Abonné.
          </p>
          <p>
            Plus largement, COLORFOL décline toute responsabilité si le manquement à une obligation résulte d'actes
            naturels, de forces ou de causes échappant à son contrôle raisonnable, y compris, sans s'y limiter, des pannes
            d'Internet, des pannes d'équipement informatique, des pannes d'équipement de télécommunication, d'autres pannes
            d'équipement, des pannes d'alimentation électrique, grèves, conflits du travail, émeutes, insurrections,
            troubles civils, pénuries de main-d'œuvre ou de matériaux, incendies, inondations, tempêtes, explosions,
            catastrophes naturelles, guerres, actions gouvernementales, décisions de tribunaux nationaux ou étrangers,
            non-exécution des obligations des tiers, et plus largement tout événement imparable et imprévu qui empêcherait
            la bonne exécution des commandes.
          </p>
          <p>
            COLORFOL décline toute responsabilité dans le cas où le Service Colorfol Premium s'avérerait incompatible avec
            certains équipements et/ou fonctionnalités de l'équipement de l'Abonné.
          </p>
          <p>
            Enfin, l'Abonné est seul responsable de son utilisation du Service Colorfol Premium et ne saurait tenir COLORFOL
            responsable de toute réclamation et/ou procédure engagée à son encontre à ce titre. L'Abonné prendra en charge
            toute réclamation, plainte ou objection et plus largement toute procédure intentée contre COLORFOL par un tiers
            en relation avec l'utilisation par l'Abonné du Service Colorfol Premium.
          </p>
          <h2>Article 12 - Propriété intellectuelle</h2>
          <p>
            La structure générale du Site, de l'Application, du Service Colorfol Premium et de tous les éléments le
            composant (tels que, mais sans s'y limiter, les logos, noms de domaine, titres ou vidéos, et les éléments
            associés tels que les photos, images, textes et biographie) des auteurs, artistes et/ou tout ayant droit sur les
            titres ou vidéos mais également les visuels présents sur les packagings) sont la propriété exclusive de COLORFOL
            et/ou de ses concédants de licence (notamment les producteurs de disques et vidéos, les maisons de disques,
            société d'auteurs) .
          </p>
          <p>
            Ces éléments sont protégés par les lois sur la propriété intellectuelle et autres, et notamment le droit
            d'auteur. L'Abonné ne peut utiliser ces éléments que dans le but d'utiliser le Service Colorfol Premium, dans le
            respect des CGU.
          </p>
          <p>
            Toute représentation totale ou partielle du Site, de l'Application, du Service Colorfol Premium et/ou des
            éléments les composant (tels que décrits ci-dessus) par quelque procédé que ce soit sans l'accord exprès de
            COLORFOL est donc interdite et serait considérée comme une contrefaçon.
          </p>
          <p>
            Tout lien hypertexte renvoyant vers le Site et utilisant la technique du « framing » (technique de programmation
            offrant la possibilité de diviser les fenêtres du navigateur en différents frames indépendants afin d'afficher
            le contenu d'un site internet externe) ou de « in-line linking » (programmation pour faire faire disparaître
            dans une page web un élément extrait d'un autre site, ce qui économise de l'espace de stockage sur le disque dur
            où est hébergé le Site et permet de cacher à un utilisateur non averti le contenu initial dont fait partie
            l'élément) est strictement interdit. Toute utilisation artificielle d'un compte Colorfol (cette utilisation
            visant par exemple à augmenter artificiellement ou anormalement la lecture de certains morceaux de musique
            spécifiques), via des processus automatisés tels que des robots et des scripts ou via tout autre moyen, est
            strictement interdite.
          </p>
          <p>
            Les Enregistrements mis à disposition au sein du Service Colorfol Premium sont des fichiers numériques protégés
            par le droit d'auteur national et international et les droits voisins. Leur écoute ne peut donc se faire que
            dans un cadre privé ou familial. Toute utilisation dans un but non privé exposera l'Abonné à des poursuites
            civiles et/ou pénales. Toute autre utilisation des Enregistrements est strictement interdite et plus
            particulièrement tout téléchargement ou tentative de téléchargement, tout transfert ou tentative de transfert
            permanent ou temporaire sur le disque dur d'un ordinateur ou de tout autre appareil (lecteurs de musique
            notamment), toute gravure ou tentative de graver un CD ou tout autre support est expressément interdit. Toute
            revente, échange ou location de ces fichiers est strictement interdite.
          </p>
          <p>
            L'Abonné reconnaît que les Enregistrements mis à sa disposition au sein du Site et de l'Application sont
            protégés par des mesures techniques de protection mises en place par COLORFOL afin de prévenir ou limiter, selon
            la technologie proposée, l'utilisation non autorisée des Enregistrements.
          </p>
          <p>
            L'Abonné s'engage à ne contourner par aucun moyen ces mesures techniques de protection, dans le but de
            télécharger ces fichiers et de permettre leur stockage au sein de l'unité de stockage de son terminal de
            réception quel qu'il soit (ordinateur, téléphone portable, baladeur et autres lecteur de disque musical
            portable, etc.).
          </p>
          <h2>Article 13 - Suspension et/ou résiliation à l'initiative de COLORFOL ou de l'Abonné </h2>
          <p>
            Sans préjudice des dommages et intérêts que COLORFOL pourrait demander, COLORFOL a le droit de suspendre l'accès
            au Service Colorfol Premium à un Abonné et/ou de résilier, sans préavis ni indemnité, son abonnement au Service
            Colorfol Premium dans les cas où l'Abonné ne respecte pas les CGU et notamment :
          </p>
          <ul>
            <li>
              Ne respecte pas les droits de propriété intellectuelle de COLORFOL ou de ses concédants de licence ;
            </li>
            <li>
              Contourne ou tente de contourner les mesures techniques de protection mises en place par COLORFOL ;
            </li>
            <li>
              Utilise plusieurs connexions simultanées au même compte ou tente de le faire ;
            </li>
            <li>
              Fournit de fausses informations au moment de sa souscription au Service Colorfol Premium ou ultérieurement ;
            </li>
            <li>
              Ne s’acquitte pas de façon totale ou partielle du paiement du prix de son abonnement ;
            </li>
            <li>
              Mène des activités préjudiciables aux intérêts commerciaux de COLORFOL.
            </li>
          </ul>
          <p>
            En ce qui concerne l'Abonné, il pourra résilier, sans préavis ni indemnité au profit de COLORFOL, son contrat ou
            son abonnement si COLORFOL manque à ses obligations substantielles définies dans les CGU ou pour un motif
            légitime tel que, notamment en cas de vol de son ordinateur ou d'incohérence technique du Service Colorfol
            Premium avec l'équipement de l'Abonné.
          </p>
          <h2>Article 14 - Modifications des CGU</h2>
          <p>
            COLORFOL a le droit de modifier à sa seule discrétion les CGU. COLORFOL informera l'Abonné, par courrier
            électronique à l'adresse email indiquée sur le compte de l'Abonné, de toute modification des CGU au moins 1 (un)
            mois avant leur entrée en vigueur. Dans le cas où l'Abonné n'accepterait pas ces modifications, il sera libre de
            résilier son abonnement depuis son compte sur le Site comme mentionné à l'article 5.4 ci-dessus, cette
            résiliation devenant effective à la prochaine échéance mensuelle de l'abonnement. Dans le cas où l'Abonné ne
            résilierait pas son abonnement, les CGU mises à jour s'appliqueront donc à compter de 1 (un) mois après
            réception de l'avis de modification des CGU.
          </p>
          <h2>Article 15 - Communauté et Service Client</h2>
          <p>
            L'Utilisateur Colorfol Premium peut contacter COLORFOL via le Site en cliquant sur la rubrique « Contactez-nous
            » ou en envoyant un mail à l'adresse contact.cm@colorfol.com.
          </p>
          <p>
            Le Support Client s'efforcera de répondre à ces demandes dans un délai raisonnable mais ne peut donner aucune
            garantie d'aucune sorte que ces demandes recevront une réponse dans un délai correspondant aux attentes de
            l'Abonné, ou que la réponse sera entièrement satisfaisante pour cet Abonné.
          </p>
          <h2>Article 16 - Nullité d'une clause</h2>
          <p>
            Dans le cas où l'une des clauses des CGU serait déclarée nulle ou annulable pour quelque raison que ce soit, les
            autres clauses resteront applicables sans aucune modification.
          </p>
          <h2>Article 17 - Juridiction – Réclamations et litiges</h2>
          <p>
            Les présentes Conditions sont régies par les lois en vigueur en République du Cameroun.
          </p>
          <p>
            En cas de litige, les parties tenteront de parvenir à un accord à l'amiable avant toute procédure judiciaire. A
            défaut d’accord amiable, toute contestation relative à la validité, à l’interprétation et/ou à l’exécution des
            CGU devra être portée devant les Tribunaux de Douala.
          </p>
        </div>
      </div>
    </div>
  );

}

export default CGUPremium;