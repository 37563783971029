import {LOCALES} from '../locales'

const translation = {
    [LOCALES.ARABE] : {
        /// Navbar ///
        'ser': "خدمات",
        'pre': "الممتازة",
        'eg': "حاول مجانا",
        'tar': "الأسعار",
        'disc' : "اكتشف كولورفول",
        'prem' : "Colofol قسط",
        'colart' : "كولوفول بريميوم",
        'freedown' : "الشحن عن بُعد مجانًا",

        'hqa' : "صوت عالي الجودة لتجربة غامرة حقاً.",
        'supp' : "ادعم فنانك مع Colorfol",
        'colprem1' : "صوت قوي، دون انقطاع مع ",
        'colprem2' : "Colorfol قسط",

        'help' : "المساعدة",
        'subpricing' : "الاشتراك والتسعير",
        'downapp' : "قم بتنزيل التطبيق",
        'getstart' : "ابدأ",

        /// Services section ///
        'disc_buy' : "اكتشف الأغاني والألبومات واشتريها مباشرةً من الفنانين المفضلين لديك.",
        'play_off' : "شغِّل أغانيك المفضلة في كل مكان، حتى في وضع عدم الاتصال بالإنترنت",
        'playlist_fy': "قوائم تشغيل مصممة خصيصاً لك، وفقاً لاستماعك",
        'pass_event' : "احصل على بطاقة دخول Colorfol الخاصة بك لحدث لا يُنسى",
        'chall' : "ويتمثل التحدي الذي نواجهه في توفير أفضل تجربة ممكنة للجماهير، من خلال واجهة سهلة وبسيطة وجودة صوت مثالية، وكذلك دعم الفنانين في تطوير فنهم وتحقيق الدخل منه.",
        'upcol' : "قم بالترقية إلى Colorfol قسط وانتقل برحلتك الموسيقية إلى المستوى التالي. استمتع بتشغيل الموسيقى دون انقطاع، حتى في وضع عدم الاتصال بالإنترنت.",
        'getprem' : "احصل على Colorfol Premium",


        'offplay' : "التشغيل دون اتصال بالإنترنت",
        'savelis' : "احفظ بياناتك من خلال الاستماع دون اتصال بالإنترنت",
        'adfre' : "استماع إلى الموسيقى بدون إعلانات",
        'enjomu' : "استمتع بالموسيقى دون انقطاع",
        'playevr' : "العب في كل مكان",
        'lispc' : "استمع على مكبرات الصوت والكمبيوتر الشخصي والأجهزة الأخرى المفضلة لديك",
        'payyr' : "ادفع بطريقتك",
        'paymob' : "ادفع باستخدام الأموال عبر الهاتف المحمول، والمزيد غير ذلك.",
        'knowmore': "هل تريد معرفة المزيد عنا؟",
        'conta' : "اتصل بنا لمزيد من الأسئلة أو لمعرفة المزيد من المعلومات",
        'contactus' : "اتصل بنا",
        'cooming' :"متاح قريب",

        /// Hero section ///
        'value' : 'اكتشف فنانك وتواصل معه وادعمه.',
        'covalue': "نحن نسهّل عليك شراء موسيقاك المفضلة والاستماع إليها وتنزيلها ومشاركتها بشكل قانوني مع أصدقائك وعائلتك، كل ذلك في مكان واحد.",
        'subvalue': "الآلاف من الأغاني للمواهب الشابة والنجوم والأسماء الكبيرة في الموسيقى الأفريقية والبودكاست ومقاطع الفيديو والفعاليات الخاصة بك (حفلات موسيقية وعروض، إلخ) كلها على نفس المنصة.",
        'fcta': 'حاول مجانا',
        'pcta': 'انضم إلى أكثر من 3000 مستخدم وشركة يؤمنون بنا.',

        /// Services section ///
        'fs_mt': "تدفق الصوت",
        'fs_ti': "اكتشف أفضل الأغاني الأفريقية",
        'fs_des': "استمع إلى أفضل الموسيقى الكاميرونية والجزائرية والإيفوارية والمزيد على هاتفك المحمول. اكتشف كل الأخبار والمفضلات في الوقت الحالي ، والأغاني القديمة ، والأخبار من الفنانين المفضلين لديك (منفردة ، وألبومات) ، وقم ببث أفضل الأغاني ، وأفضل الأغاني الأفريقية واكتشف المزيد من المواهب الشابة الاستثنائية. عش تجربة موسيقية لا تضاهى على Colorfol. قم ببث وإنشاء قوائم التشغيل الخاصة بك ومشاركتها مع أصدقائك ، وتنزيل الموسيقى المفضلة لديك والاستماع إليها دون اتصال بالإنترنت",
        'fs_cta': "اكتشف أيضًا ملفات البودكاست الخاصة بنا",

        'ss_mt': "يتدفقون السادس",
        'ss_ti': "اكتشف أفضل مقاطع الفيديو الموسيقية الأفريقية",
        'ss_des': "يعد الفيديو أحد أكثر المحتويات استهلاكًا على الإنترنت ، ويعمل الفنانون الأفارقة كثيرًا لإنتاج المزيد والمزيد من مقاطع الفيديو الاستثنائية ، لذلك فكرنا في إتاحتها لك حتى تتمكن من إرضاء نفسك. اكتشف ودفق ونزل أفضل مقاطع اللحظة من الفنانين المفضلين لديك واسمح لنفسك بالتنقل من خلال إيقاعات وألوان ورقصات ومرئيات أغانيك المفضلة",
        'ss_cta': "لاكتشاف",

        'ts_mt': "حدث",
        'ts_ti': "الأحداث الموسيقية الحصرية في متناول يدك",
        'ts_des': "لا تفوت أي معلومات عن العروض الرائعة والعروض والأحداث الثقافية والحفلات الموسيقية للفنانين المفضلين لديك مع إمكانية شراء التذاكر الخاصة بك عبر الإنترنت. نبقيك على اطلاع بأخبار الأحداث الثقافية والأحداث من حولك. تعرف على الأحداث الثقافية الحالية أو القادمة التي تقام بالقرب منك. احجز مقاعدك واشتري تذاكر للحفلات الموسيقية على Colorfol",
        'ts_cta': "لاكتشاف",

        /// Premium  section ///
        'pre_mt': "استمتع بأغانيك المفضلة دون قيود مع Colorfol قسط",
        'pre_des': "استمتع بأفضل جودة صوت وفيديو لألقابك المفضلة ، بلا حدود دون أي إعلان وبدون انقطاع. قم بتنزيل أغانيك المفضلة واستمع إليها بدون اتصال بالإنترنت. استفد من المشغل المدمج لتشغيل كل المحتوى الصوتي على هاتفك المحمول.",
            /// Try free section ///
            'eg_mt': "ثلاث خطوات سهلة",
            'eg_cta': "يبدأ",

            'fst_des': "قم بتنزيل التطبيق من هاتف Android أو iPhone.",
            'fst_ti': "تحميل",

            'sst_des': "إذا كنت مستخدمًا جديدًا ، فمن السهل جدًا الاشتراك وإنشاء حسابك.",
            'sst_ti': "إفتح حساب",

            'tst_des': "اختر البلد (البلدان) وأنواع الموسيقى الخاصة بك واستمتع بأفضل الموسيقى والبودكاست الأفريقية.",
            'tst_ti': "تدفق",

        /// Pricing section ///
        'pri_mt': "خطة التعرفة",
        'pri_des': "اكتشف عروضنا المختلفة.",

        'fpc_mt': "Premium",
        'fpc_ti': "0.2 €/ يوم",
        'fpc_si': "0.8 €/أسبو",
        'fpc_foi': "02 €/شهر",
        'fpc_fii': "20 €/سنوي",
        'fpc_sii': "Colorfol Njooh",

        'fpri': "وصول غير محدود إلى المحتوى",
        'spri': "جودة صوت أفضل",
        'tpri': "قراءة من اختيارك",
        'fopri': "كلمات",
        'fipri': "تدوين صوتي",
        'sipri': "راديو عبر الإنترنت",
        'sepri': "شراء التذاكر",
        'hepri': "الاستماع دون اتصال",
        'nipri': "لا اعلانات",
        'tepri': "وصول غير محدود إلى محتوى الفيديو",
        'elpri': "وصول غير محدود إلى محتوى الصوت",
        'twpri': "دعاية",

        'spc_mt': "دفع",
        'tpc_mt': "فريميوم",

    
            /// Footer ///
            'ft_fmt': "خدمات",
            'fmt_fi': "بث الموسيقى والبودكاست",
            'fmt_si': "بث الفيديو",
            'fmt_ti': "الفعاليات",
            'fmt_foi': "شبكة موسيقية شبكية",
            'fmt_fii': "المراجعات",
            
            'ft_smt': "جهات الاتصال",
            'smt_fi': "contact.cm@colorfol.com",
            'smt_si': "(+237) 690 81 72 84",
            'smt_ti': "Akwa - Douala - Cameroun",
            'smt_foi': "Contact.ci@colorfol.com",
            'smt_fii': "(+225) 0 787 458 582",
            'smt_sii': "Cocody Angré Petro Ivoire Abidjan- Côte d’Ivoire",
            'smt_sei': "contact.fr@colorfol.com",
            'smt_hui': "(+33) 6 05 87 57 04 ",
            'smt_nei': "Paris, France",
            'terms' : "الشروط والأحكام",
            'privacy' : "سياسة الخصوصية",
            'copyright' : "جميع الحقوق محفوظة لشركة Colorfol SARL © 2023",
            'diss' : "اكتشف",
            'lang': "اللغة الإنجليزية",

            'ft_tmt': "احصل على التطبيق",

        'ourcompany' : "نوتري إنتربرايز",
        'waw' : "من نحن؟",
        'ov' : "نوتري فيجن",
        'ot' : "نوتري إيكيب",
        'fu' : "سوفيز-نوس",
    }
}

export default  translation