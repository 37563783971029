import en from './en-US';
import fr from './fr-Fr';
import ar from './ar-DZ';

const languageCode = {
    ...en,
    ...fr,
    ...ar,
}

export default languageCode