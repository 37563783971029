import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/carousel.module.css'

const InfiniteCarousel = ({ cards }) => {
    const settings = {
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000, // Adjust the autoplay speed as needed
        arrows: false,
        // fade: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const settings2 = {
        infinite: true,
        speed: 6000, // Adjust the speed to control the duration of the animation
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, // Set to 0 to disable automatic sliding
        cssEase: "linear", // Use linear easing for a constant speed
        variableWidth: true,
        responsive: [
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    return (
        <Slider {...settings2}>
            {cards.map((card, index) =>(
                <div key={index} className="position">
                    {card}
                </div>
            ))}
        </Slider>
    );
};

export default InfiniteCarousel;
