import translate from '../../i18n/translate'
import '../../App.css';
import React, { useEffect, useState } from 'react';

const Popup = ({ message, onClose }) => {
    const [showPopup, setShowPopup] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopup(false);
            onClose();
        }, 20000);

        return () => clearTimeout(timer);
    }, [onClose]);

    const handlePopupClick = () => {
        setShowPopup(false);
        onClose();
    };

    return (
        showPopup && (
            <div className="popup" onClick={handlePopupClick}>
                <div className="popup-header">
                    {/* <h3>{title}</h3> */}
                    <button onClick={handlePopupClick}>&times;</button>
                </div>
                <div className="popup-content">
                    <p>{message}</p>
                </div>
            </div>
        )
    );
}
export default Popup