import translate from '../../i18n/translate'
import '../../App.css';
import styles from '../styles/why.module.css'
import back1 from '../../assets/new_images/back1.png';
import back2 from '../../assets/new_images/back2.svg';
import back4 from '../../assets/new_images/back3.svg';
import back3 from '../../assets/new_images/back4.svg';
import InfiniteCarousel from "./InfiniteCarousel";
import '../styles/carousel.module.css'

const WhySectionComponent = () => {

  const items = [
    <div className="car-elt">
      <div className="placeholder">
        <div className='backg'></div>
        <div className='largecircle'></div>
        <div className='bigcircle'></div>
        <div className="mediumcircle"></div>
        <div className="smallcircle"></div>
        <img className="carouimg" src={back3}/>
      </div>
      <span className='carouspan1'>{translate('disc_buy')}</span>
    </div>,
    <div className="car-div"></div>,
    <div className="car-elt">
      <div className="placeholder"><img className="carouimg2" src={back2}/></div>
      <span className='carouspan1'>{translate('play_off')}</span>
    </div>,
    <div className="car-div"></div>,
    <div className="car-elt">
      <div className="placeholder"><img className="carouimg22" src={back4}/></div>
      <span className='carouspan1'>{translate('playlist_fy')}</span>
    </div>,
    <div className="car-div"></div>,
    <div className="car-elt">
      <div className="placeholder"><img className="carouimg3" src={back1}/></div>
      <span className='carouspan1'>{translate('pass_event')}</span>
    </div>,
    <div className="car-div"></div>,
  ]

  return (
      <div className="fulll">
        <div className={`why-head ${styles.whyHeader}`}>
          <span className='why-title'>{translate("hqa")}</span>
        </div>

        <InfiniteCarousel className="" cards={items}/>

        {/*<div className={`why-section ${styles.whySection}`}>*/}

        {/*  <div className="carou">*/}
        {/*    <div className="car-elt">*/}
        {/*      <div className="placeholder">*/}
        {/*        <div className='backg'></div>*/}
        {/*        <div className='largecircle'></div>*/}
        {/*        <div className='bigcircle'></div>*/}
        {/*        <div className="mediumcircle"></div>*/}
        {/*        <div className="smallcircle"></div>*/}
        {/*        <img className="carouimg" src={back3}/>*/}
        {/*      </div>*/}
        {/*      <span className='carouspan1'>{translate('disc_buy')}</span></div>*/}
        {/*    <div className="car-elt">*/}
        {/*      <div className="placeholder"><img className="carouimg2" src={back2}/></div>*/}
        {/*      <span className='carouspan1'>{translate('play_off')}</span></div>*/}
        {/*    <div className="car-elt">*/}
        {/*      <div className="placeholder"><img className="carouimg22" src={back4}/></div>*/}
        {/*      <span className='carouspan1'>{translate('playlist_fy')}</span></div>*/}
        {/*    <div className="car-elt">*/}
        {/*      <div className="placeholder"><img className="carouimg3" src={back1}/></div>*/}
        {/*      <span className='carouspan1'>{translate('pass_event')}</span></div>*/}
        {/*  </div>*/}

        {/*</div>*/}
      </div>
  )
}

export default WhySectionComponent