import React from 'react';
import styles from '../layouts/styles/cgu.module.css'

function Privacy() {
	return (
		<div className={styles.page_wrapper}>
			<div className={styles.background_container}></div>
			<div className={styles.content_container}>
				<div className={styles.all_page}>
					<h1> colorfol </h1>
					<p>Pour le moment le streaming sur colorfol se fait uniquement sur nos applications mobiles. Cliquer sur les liens suivants pour obtenir l'application.</p>
					<p>
						<a className={styles.link} href="https://apps.apple.com/cm/app/colorfol/id1603123058" >Colorfol IOS app</a>
					</p>
					<p>
						<a className={styles.link} href="https://play.google.com/store/apps/details?id=com.dev.mobile.colortech&hl=fr&gl=FR" >Colorfol Android app</a>
					</p>
				</div>
			</div>
		</div>
	);
}

export default Privacy;