import translate from '../../i18n/translate'
import '../../App.css';
import styles from '../styles/tarif.module.css'
import CarteOffres from '../../components/carteOffres';
import { ArrowBackOutline, ArrowForwardOutline } from 'react-ionicons';
import { offres } from '../../components/offres';
import { freemium } from '../../components/freemium';
import psl1 from '../../assets/img/visa.png';
import psl2 from '../../assets/img/mastercard.jpg';
import psl3 from '../../assets/img/eu.png';
import psl4 from '../../assets/img/mtn.jpg';
import psl5 from '../../assets/img/Orange_Money.jpg';
import psl6 from '../../assets/img/yup.png';
import rd from '../../assets/img/logo couche_Plan de travail.png'
import psl7 from '../../assets/img/wari.png';
import { useState } from 'react';

const TarifContainer = () => {
  // Set local state
  const [index, setIndex] = useState(0)
  const slideLeft = () => {
    if (index - 1 >= 0) {
      setIndex(index - 1);
    }
  };

  const slideRight = () => {
    if (index + 1 <= offres.length - 1) {
      setIndex(index + 1);
    }
  };

  return (
    <div className={`events-cta2 ${styles.tarifContainer}`}>
      <div className='prh'>
        <h2>{translate("pri_mt")}</h2>
        <span>{translate("pri_des")}</span>
      </div>

      <a id='tar'></a>

      <div className={`prb ${styles.tarifItems}`}>
        <div className={`prc ${styles.tarifItem}`}>
          <span className='prcta2 lph'>{translate("tpc_mt")}</span>
          <div className='card-container'>
            {
              freemium.map((person) => {
                return (<CarteOffres {...person} cardStyle={"activeCard"} />);
              })
            }
          </div>
        </div>

        <div className={`prc ${styles.tarifItem}`}>
          <div className='prche'>
            <span className='prcta2 lph'>{translate("fpc_mt")}</span>
            <div className='card-container'>
              {
                index < offres.length - 1 && (
                  <span
                    className="rightBtn"
                    onClick={slideRight}
                  >
                    <ArrowForwardOutline
                      onClick={slideRight}
                      className='mt-4'
                      color={'#00000'}
                      title={"next"}
                      height="28px"
                      width="28px"
                    />
                  </span>
                )
              }

              {
                index !== 0 && (
                  <span
                    className="leftBtn"
                    onClick={slideLeft}
                  >
                    <ArrowBackOutline
                      color={'#00000'}
                      className='mt-4'
                      title={"prev"}
                      height="28px"
                      width="28px"
                    />
                  </span>
                )
              }


              {
                offres.map((person, n) => {
                  let position = n > index ? "nextCard" :
                    (
                      n === index ? "activeCard" : "prevCard"
                    )

                  return (<CarteOffres {...person} cardStyle={position} />
                  )
                })
              }
            </div>
          </div>
        </div>


        <div className={`prc ${styles.tarifItem}`}>
          <span className='prcta2 lph'>{translate("spc_mt")}</span>

          <span className='plbc'> <img alt="#" src={rd} className='plb' /></span>
          <span className='pslb pl1'> <img alt="#" src={psl1} className='plb' /></span>
          <span className='pslb pl2'> <img alt="#" src={psl2} className='plb' /></span>
          <span className='pslb pl3'> <img alt="#" src={psl3} className='plb' /></span>
          <span className='pslb pl4'> <img alt="#" src={psl4} className='plb' /></span>

          <span className='pslb pl5'> <img alt="#" src={psl5} className='plb' /></span>
          <span className='pslb pl6'> <img alt="#" src={psl6} className='plb' /></span>
          <span className='pslb pl7'> <img alt="#" src={psl7} className='plb' /></span>
        </div>
      </div>
    </div>
  )
}

export default TarifContainer