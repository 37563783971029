import { useContext, useMemo, useState } from 'react'
import NavigationContext from '../../datamanager/contexts/navigationContext'
import { HashLink as Linka } from 'react-router-hash-link';
import styles from '../styles/navbarMobileMenu.module.css'
import translate from '../../i18n/translate';
import ArrowLeft from "../../assets/new_images/ArrowLeft.svg"

const logoImage = require("../../assets/new_images/logo_typo.png").default

const NavbarMobileMenuItem = ({ title, link, onClick, external }) => {
  const { currentTarget, navigateTo } = useContext(NavigationContext)

  const activeClass = useMemo(() => currentTarget === title && styles.mobileMenuItemActive, [currentTarget, title])

  const elt = external ? (
    <a href={link} className={`${styles.mobileMenuItem} ${activeClass} ${styles.mobileMenuItemContainer}`} target='_blank' rel='noopener noreferrer'>
      <span>{title}</span>
    </a>
  ) : (
    <Linka
      to={link}
      className={styles.mobileMenuItemContainer}
    // onClick={() => navigateTo(title)}
    >
      <div className={`${styles.mobileMenuItem} ${activeClass}`}>
        <span>{title}</span>
      </div>
    </Linka>
  );

  return (
    elt
  )
}

const NavbarMobileMenu = () => {
  const { showMenu, closeMobileMenu } = useContext(NavigationContext)

  const displayMobileMenu = useMemo(() => showMenu && styles.mobileMenuContainerVisible, [showMenu])
  const [messageVisible, setMessageVisible] = useState(false);
  const handleClick = (e) => {
    e.preventDefault(); // Empêche la navigation si le lien est "#"
    setMessageVisible('Bientôt disponible ');
    setTimeout(() => setMessageVisible(''), 3000);
  };
  return (
    <>
      <section className={`${styles.mobileMenuContainer} ${displayMobileMenu}`}>
        <div className={styles.mobileMenuHeader}>
          {/* <img
            src={logoImage}
            alt="logo"
            className={styles.mobileMenuLogo}
          /> */}

          <img
            src={ArrowLeft}
            alt="logo"
            className={styles.mobileMenuArrow}
          />

          <span className={styles.mobileMenuClose} onClick={closeMobileMenu}>&times;</span>

          {/*{*/}
          {/*    showMenu && <span className={styles.mobileMenuContainerBackground} onClick={closeMobileMenu} />*/}
          {/*}*/}
        </div>

        <div className={styles.mobileMenuBody}>
          <NavbarMobileMenuItem title={translate('disc')} link="https://www.store.colorfol.com" external={true} />
          <NavbarMobileMenuItem title={translate("prem")} onClick={handleClick} link="#" external={false} />
          {messageVisible && <div style={{ color: 'orange', position: 'fixed', bottom: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>{messageVisible}</div>}
          <NavbarMobileMenuItem title={translate("colart")} link="https://www.artists.colorfol.com" external={true} />
        </div>

      </section>
    </>
  )
}

export default NavbarMobileMenu