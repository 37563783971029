import React from 'react';
import styles from '../layouts/styles/cgu.module.css'

function CU() {

  return (

    <div className={styles.page_wrapper}>
      <div className={styles.background_container}></div>
      <div className={styles.content_container}>
        <div className={styles.all_page}>
          <h1>Charte de l'utilisateur de l'application COLORFOL</h1>
          <p>
            Bienvenue sur Colorfol, nous sommes ravis de vous accueillir sur notre plateforme de diffusion d’œuvres
            musicales. Veuillez prendre connaissance des règles qui s’applique à tout utilisateur des Services Colorfol.
          </p>
          <p>
            COLORFOL se réserve le droit de modifier ces règles à tout moment et vous invite à les consulter régulièrement
            pour être jour sur leur contenu.
          </p>
          <p>
            Afin de garantir une expérience positive pour tous les utilisateurs, veuillez prendre connaissance et respecter
            les règles suivantes :
          </p>
          <ol>
            <li>
              Respect des droits d’auteur : ne téléchargez, ne partagez, ni ne diffusez du contenu sans avoir obtenues les
              autorisations nécessaires. Vous êtes soumis à l’obligation de respecter la propriété intellectuelle et les
              droits d’auteur des artistes dont les œuvres sont mises à votre disposition sur notre plateforme, ainsi que
              celle du propriétaire de la plateforme.
            </li>
            <li>
              Attitudes prohibées : il vous est interdit de copier, reproduire, redistribuer, extraire, enregistrer,
              transférer, exécuter, créer un lien afficher au public, diffuser ou mettre à la disposition du public, ou
              toute autre utilisation qui n’est pas expressément autorisé en vertu des accords ou de la loi applicable, ou
              qui enfreint les droits de propriété intellectuelle.
            </li>
            <li>
              Contenu approprié : Evitez de publier du contenu offensant, diffamatoire, illégal ou contraire aux bonnes
              mœurs. Nous nous réservons le droit de supprimer tout contenu inapproprié.
            </li>
            <li>
              Comportement respectueux : soyez courtois et respectueux envers les autres membres et envers le travail
              proposé par les artistes sur la présente plateforme. Aucun comportement abusif, harcèlement, ou discours de
              haine ne sera toléré.
            </li>
            <li>
              Sécurité de votre compte : protégez votre compte en utilisant un mot de passe fort et ne divulguez pas vos
              informations de connexion. Signalez toute activité suspecte à notre équipe de support en nous adressant un
              mail à l’adresse contact.cm@colorfol.com ou en nous appelant au numéro xxx.
            </li>
            <li>
              Protection de la vie privée : respectez la vie privée des autres utilisateurs, ne partagez pas d’informations
              personnelles sans autorisation.
            </li>
            <li>
              Utilisation légale : utilisez la plateforme conformément à toutes les lois locales et internationales en
              vigueur. Evitez de participer à des activités illégales ou frauduleuses.
            </li>
            <li>
              Respect des conditions d’utilisation : conformez-vous aux conditions générales d’utilisation de COLORFOL (
              lien vers les conditions générales d’utilisation). Toute violation pourrait entrainer la résiliation de votre
              compte.
            </li>
            <li>
              Mises à jour et Modifications : soyez attentifs aux mises à jour et aux modifications des conditions
              d’utilisation. Votre utilisation continue de la plateforme signifie votre acceptation des changements apportés
            </li>
            <li>
              Feedbacks constructifs : nous apprécions vos commentaires. Si vous avez des suggestions ou des préoccupations
              partagez-les de manière constructive dans l’espace réservé aux commentaires.
            </li>
            <li>
              Support client : en cas de problème, contactez-nous par mail à l’adresse contact.cm@colorfol.com ou par
              téléphone au numéro xxx.
            </li>
            <li>
              En acceptant cette charte, vous contribuez à créer une communauté respectueuse sur COLORFOL. Merci de votre
              coopération
            </li>
          </ol>
        </div>
      </div>
    </div>

  );

}

export default CU;