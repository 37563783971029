import translate from '../../i18n/translate'
import logo from '../../assets/img/logo-colorfol--matriciel.png';
import logo2 from '../../assets/new_images/logo_typo.svg';
import light_from_scy from '../../assets/new_images/light_from_scy.svg';
import noise from '../../assets/new_images/noise.svg';
import '../../App.css';
import styles from '../styles/navbarMobileMenu.module.css'
import { HashLink as Linka } from 'react-router-hash-link';
import { Close, Menu } from 'react-ionicons';
import { useContext, useEffect, useState } from 'react';
import NavigationContext from '../../datamanager/contexts/navigationContext';
import ScrollNavbar from "./NavBarHideOnScrool";
import Popup from './Popup';

const Navbar = () => {
    const { openMobileMenu } = useContext(NavigationContext)
    const [showPopup, setShowPopup] = useState(false);

    const handleButtonClick = () => {
        setShowPopup(true);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };
    // State variables to manage scroll behavior
    const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
    const [top, setTop] = useState(0);
    useEffect(() => {
        // Function to handle scroll events
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            if (prevScrollpos > currentScrollPos) {
                setTop(0); // Show navbar
            } else {
                setTop(-50); // Hide navbar
            }
            setPrevScrollpos(currentScrollPos);
        };
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollpos]);

    const navbarStyle = {
        top: `${top}em`,
        transition: 'top 0.3s',
    };

    return (
        <div className={`nav-bar`} style={navbarStyle}>
            <Menu
                color={'#fff'}
                height="1.8rem"
                width="1.8rem"
                className={styles.mobileMenuTrigger}
                onClick={openMobileMenu}
            />
            <a href="https://www.colorfol.com" target="_blank" rel="noopener noreferrer">
                <span className={`nav-logo ${styles.bigMenuLogo}`}>
                    <img src={logo2} alt="floatings-icons" />
                </span>
            </a>

            <ul className={`nav-elts ${styles.listMenu}`}>
                <li className={styles.linkItemBigMenu}>
                    <a href='https://www.store.colorfol.com' className='linker' target='_blank' rel='noopener noreferrer'>
                        <span>{translate("disc")}</span>
                    </a>
                    {/*  <Linka to='store.colorfol.com' className='linker'>{translate("disc")}</Linka> */}
                </li>
                <li className={styles.linkItemBigMenu}>
                    <Linka to='#' className='linker' onClick={handleButtonClick}>{translate("prem")}</Linka>
                    {showPopup && (
                        <Popup
                            message={translate("cooming")}
                            onClose={handlePopupClose}
                        />
                    )}
                </li>
                <li className={styles.linkItemBigMenu}>
                    <a href='https://www.artists.colorfol.com' className='linker' target='_blank' rel='noopener noreferrer'>
                        <span>{translate("colart")}</span>
                    </a>
                </li>
                <li></li>
                <a href="download"><button className='freedown'>{translate('freedown')}</button></a>
            </ul>

        </div>
    )
}

export default Navbar