import translate from "../i18n/translate";
export const offres = [
    {
      name: translate("fpc_ti"),
      details: [
        {
          opt: translate("fpri")
        },
        {
            opt: translate("spri")
        },
        {
            opt: translate("tpri")
        },
        {
            opt: translate("fopri")
        },
        {
            opt: translate("fipri")
        },
        {
            opt: translate("sipri")
        },
        {
            opt: translate("sepri")
        },
        {
            opt: translate("hepri")
        },
        {
            opt: translate("nipri")
        },
        
        ],
    },
    {
        name: translate("fpc_si"),
        details: [
          {
            opt: translate("fpri")
          },
          {
              opt: translate("spri")
          },
          {
              opt: translate("tpri")
          },
          {
              opt: translate("fopri")
          },
          {
              opt: translate("fipri")
          },
          {
              opt: translate("sipri")
          },
          {
              opt: translate("sepri")
          },
          {
              opt: translate("hepri")
          },
          {
              opt: translate("nipri")
          },
          
          ],
    },
    {
        name: translate("fpc_foi"),
        details: [
          {
            opt: translate("fpri")
          },
          {
              opt: translate("spri")
          },
          {
              opt: translate("tpri")
          },
          {
              opt: translate("fopri")
          },
          {
              opt: translate("fipri")
          },
          {
              opt: translate("sipri")
          },
          {
              opt: translate("sepri")
          },
          {
              opt: translate("hepri")
          },
          {
              opt: translate("nipri")
          },
          
          ],
    },
    {
        name: translate("fpc_fii"),
        details: [
          {
            opt: translate("fpri")
          },
          {
              opt: translate("spri")
          },
          {
              opt: translate("tpri")
          },
          {
              opt: translate("fopri")
          },
          {
              opt: translate("fipri")
          },
          {
              opt: translate("sipri")
          },
          {
              opt: translate("sepri")
          },
          {
              opt: translate("hepri")
          },
          {
              opt: translate("nipri")
          },
          
          ],
      },
  ];