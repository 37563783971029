import React from 'react';
import styles from '../layouts/styles/cgu.module.css'

function MENTION() {

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.background_container}></div>
      <div className={styles.content_container}>
        <div className={styles.all_page}>
          <h1>MENTIONS LEGALES <p><a href="http://www.colorfol.com">www.colorfol.com</a></p></h1>
          <h2>Article 1 - champ d’application </h2>
          <p>
            Les présentes mentions légales sont portées à la connaissance des utilisateurs et visiteurs du site internet et
            de l’application <a href="http://www.colorfol.com">www.colorfol.com</a> afin de préciser l’identité des
            différents intervenants dans le cadre de sa réalisation et de son suivi.
          </p>
          <h2>Article 2 - Editeur du site</h2>
          <p>
            L’application Colorfol et le site <a href="http://www.colorfol.com">www.colorfol.com</a> Sont éditiés par
            COLORFOL Cameroun, Société à Responsabilité Limitée, au capital de 980.000 Francs CFA, dont le siège social est
            situé Immeuble SOCAR, Akwa, Douala Cameroun immatriculée au Registre du Commerce et du Crédit Mobilier de
            Douala, sous le numéro RC/DLN/2022/B/095.
          </p>
          <p>
            Numéro de téléphone : XXX
          </p>
          <p>
            E-mail : XXX
          </p>
          <h2>Article 3 - Directeur de publication</h2>
          <p>
            XXXXX est le directeur de publication et répond au numéro de téléphone XXX et à l’adresse E-mail : XXXXXX
          </p>
          <h2>Article 4 - Hébergeur du site</h2>
          <p>
            Le site est hébergé par : XXXXX
          </p>
          <h2>Article 5 - accès au site</h2>
          <p>
            Le Site internet <a href="http://www.colorfol.com">www.colorfol.com</a> et son application sont accessible sept
            jours sur sept, vingt-quatre heures sur vingt-quatre sauf cas de force majeure, interruption programmée ou non
            et pouvant découler d’une nécessité de maintenance.
          </p>
          <h2>Article 6 - collecte des données</h2>
          <p>
            Les informations sur les données collectées lorsque vous naviguez sur le site internet et son application
            Colorfol sont contenues dans le document intitulé « Politique de confidentialité » disponible en suivant le lien
            : (lien de la politique de confidentialité, dont vous êtes invités à prendre connaissance.
          </p>
          <h2>Article 7 - Propriété intellectuelle et contrefaçons.</h2>
          <p>
            COLORFOL est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les
            éléments accessibles sur le site internet et son application, notamment les textes, images, graphismes, logos,
            vidéos, architecture, icônes et sons.
          </p>
          <p>
            Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site
            et/ou de son application quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
            préalable de COLORFOL.
          </p>
          <p>
            Toute exploitation non autorisée du site et ou de son application ou de l’un quelconque des éléments qu’il
            contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux lois régissant la
            protection de la propriété intellectuelle au Cameroun.
          </p>
        </div>
      </div>
    </div>

  );

}

export default MENTION;