import React from 'react';
import styles from '../layouts/styles/cgu.module.css'

function CGU() {

        return (
                <div className={styles.page_wrapper}>
                        <div className={styles.background_container}></div>
                        <div className={styles.content_container}>
                                <div className={styles.all_page}>
                                        <h1>Conditions Générales d’Utilisation du service gratuit COLORFOL </h1>

                                        <h2>Article 1 - Remarques générales </h2>
                                        <p>
                                                Le présent document constitue les Conditions d'Utilisation du Service Gratuit Colorfol qui comprend le site Internet accessible à l'adresse https://www.Colorfol.com (ci-après le « Site »), ainsi que ses applications bureau, tablette et mobile (ci-après l'« Application »). Elles sont applicables à tous les utilisateurs du site web www.colorfol.com ainsi que tous les sites web y associés, détenus et exploités par COLORFOL Cameroun. Elles sont relatives à l'utilisation de tout contenu, information, produit et/ou service qui figurent sur le site web.
                                        </p>
                                        <p>
                                                Ces Conditions Générales d’utilisation constituent un accord juridiquement contraignant entre COLORFOL et un utilisateur individuel ou une entité unique. De ce fait, avant l’utilisation du site web ou de la plateforme ou de tout site web associé, l’utilisateur est tenu de lire attentivement les présentes conditions générales d’utilisation et accepte que toute inscription et/ou utilisation du site web ou de la plateforme ou de tout site web associé, s’entend de la reconnaissance d’avoir lu, compris et accepté d’être lié par les présentes conditions générales d’utilisation
                                        </p>
                                        <p>
                                                Le Site et l'Application sont créés, développés et exploités par COLORFOL Cameroun (ci-après « COLORFOL »), Société à Responsabilité Limitée, au capital de 980.000 Francs CFA, dont le siège social est situé Immeuble SOCAR, Akwa, Douala Cameroun immatriculée au Registre du Commerce et du Crédit Mobilier de Douala, sous le numéro RC/DLN/2022/B/095.
                                        </p>
                                        <p>
                                                Pour accéder et utiliser le Service Gratuit Colorfol, l'utilisateur doit (i) s'inscrire et créer un compte personnel et (ii) accepter sans aucune réserve, au moment de son inscription, les présentes conditions d'utilisation. (Y compris la Politique de confidentialité) (ci-après les « Conditions »).
                                        </p>
                                        <h2>Article 2 – Présentation du Service Gratuit Colorfol </h2>
                                        <p>
                                                Le Service Gratuit Colorfol est un service financé par la publicité proposant du streaming gratuit à la demande d'enregistrements sonores, visuels (ci-après les « Enregistrements ») et d'autres contenus musicaux et culturels sans téléchargement ni connexion Internet. Le Service Gratuit Colorful propose également le téléchargement des fonctionnalités avec de la publicité audio et display, sur tout appareil (qu'il soit non portable ou portable) de manière illimitée (le « Service Colorfol Gratuit ») pour chaque utilisateur enregistré (ci-après l'« Utilisateur Colorfol Gratuit »).
                                        </p>
                                        <p>
                                                Le service gratuit Colorfol comprend de la musique, des listes de lecture et d'autres recommandations de contenu.
                                        </p>
                                        <p>
                                                Le streaming est possible piste par piste, en créant des playlists, ou via les radios.
                                        </p>
                                        <h2>Article 3 - Accès et utilisation du Service Gratuit Colorfol </h2>
                                        <p>
                                                L'accès au Service Colorfol Gratuit n’est pas nécessairement subordonné à la création d’un compte utilisateur et l’utilisateur doit avoir au moins …. ans ou obtenir l’accord de son parent ou tuteur s’il est mineur.
                                        </p>
                                        <p>
                                                L'utilisation du Service Gratuit Colorfol nécessite une connexion Internet pour les ordinateurs et un service internet pour les appareils portables. Ces connexions ne sont pas assurées par Colorfol, l'Utilisateur Colorfol Freemium doit donc au préalable souscrire à une offre Internet afin de pouvoir utiliser le Service Colorfol Freemium.
                                        </p>
                                        <p>
                                                Le catalogue musical disponible, dans le cadre du Service Colorfol Gratuit, est lié aux accords en vigueur avec les ayants droit et peut donc évoluer. COLORFOL ne peut garantir la disponibilité d'un titre ou d'un album déterminé ou d'un artiste ou d'un groupe dans le catalogue du Service Gratuit Colorfol.
                                        </p>
                                        <p>
                                                De plus, COLORFOL ne peut garantir qu'un titre, un album, un artiste ou un groupe déterminé dans le catalogue du Service Gratuit Colorfol sera disponible indéfiniment. COLORFOL ne peut en aucun cas être tenu pour responsable de la suppression de parties du contenu du catalogue mis à disposition.
                                        </p>
                                        <p>
                                                En tant qu'Utilisateur Colorfol Gratuit, vous pouvez activer le Service Colorfol Gratuit sur un ordinateur personnel (PC ou Mac) et un appareil portable compatible. Le Service Gratuit Colorfol n’est accessible que depuis une seule connexion à la fois (ordinateur personnel enregistré ou appareil portable compatible). Colorfol dispose des moyens techniques pour vérifier l'absence de plusieurs connexions simultanées sur un même compte et donc détecter les tentatives de connexions multiples.
                                        </p>
                                        <h2>Article 4 – Clause de confidentialité. </h2>
                                        <p>
                                                Votre vie privée est importante pour nous. COLORFOL Cameroun assure la protection des informations collectées lors de vos connexions ainsi que l'utilisation et la divulgation de vos informations personnelles par les Distributeurs. Pour en savoir plus sur la manière dont vos données sont traitées veuillez consulter notre politique de confidentialité via ce lien (lien).
                                        </p>
                                        <h2>Article 5 – Disponibilité et modification du Service Gratuit Colorfol </h2>
                                        <p>
                                                Le Service Colorfol Gratuit est accessible 24h/24 et 7j/7, dans la limite des Conditions Générales et notamment des conditions de l'article 6 ci-dessous.
                                        </p>
                                        <p>
                                                Toutefois, COLORFOL se réserve le droit d'apporter toute modification et amélioration au Service Gratuit Colorfol qu'elle estime nécessaire. COLORFOL garantit néanmoins que cela n'altèrera pas la qualité ni ne modifiera substantiellement les fonctionnalités du Service Gratuit Colorfol.
                                        </p>
                                        <p>
                                                Par ailleurs, COLORFOL a le droit, sans préavis ni indemnité, de supprimer temporairement l'accès au Service Colorfol Gratuit si nécessaire afin d'assurer le maintien ou la continuité du Service Colorfol Gratuit. COLORFOL a également le droit, sans indemnité, d'arrêter définitivement le Service. Tout arrêt définitif du Service Gratuit Colorfol sera notifié via le Site ou via l'Application.
                                        </p>
                                        <h2> Article 6 – Responsabilité des Utilisateurs Colorfol Gratuit </h2>
                                        <p>
                                                Chaque Utilisateur Colorfol Gratuit peut publier des messages, informations et/ou commentaires sur le Site et l'application. L'Utilisateur Colorfol Gratuit est responsable de tout message, contenu ou information publié par lui-même sur le Site ou l'application; COLORFOL est uniquement considéré comme un hébergeur de services Internet et ne peut être tenu pour responsable du contenu publié par l'Utilisateur Colorfol Gratuit sur le Site ou l'application, et sur lequel COLORFOL n'exerce aucun contrôle ou supervision.  COLORFOL peut récupérer le nom d'utilisateur de l’utilisateur ou demander à ce dernier de le modifier pour quelque raison que ce soit.
                                        </p>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit accepte expressément, afin de ne pas engager sa responsabilité, à ce que les messages qu'il publie sur le Site ou l'application ne visent pas notamment mais sans s'y limiter à :
                                        </p>
                                        <ul>
                                                <li>
                                                        Constituer une violation des droits de propriété intellectuelle de tiers ; le membre s'engage donc à ne pas publier, sur le Site ou l'application bureautique, de contenu protégé au titre du droit d'auteur, d'une marque déposée ou plus généralement tout contenu protégé par tout autre droit de propriété intellectuelle détenu par des tiers sans l'accord préalable du ou des propriétaires desdits droits ;
                                                </li>
                                                <li>
                                                        Contenir tout virus informatique susceptible d'interrompre, de détruire ou d'affecter les fonctionnalités du Site ou de l'application;
                                                </li>
                                                <li>
                                                        Faire l’apologie des délits ou contenir des messages illégaux ou menaçants ou à caractère pédophile, pornographique, diffamatoire, obscène, haineux, raciste, antisémite, xénophobe, révisionniste ou contraire à l'ordre public et aux bonnes mœurs;
                                                </li>
                                                <li>
                                                        Porter atteinte à un droit à la vie privée ou porter atteinte à la dignité de tiers ;
                                                </li>
                                                <li>
                                                        Encourager la violence, le fanatisme, la criminalité, le suicide, la haine liée à la religion, à la race, au sexe, aux préférences sexuelles, à l'origine ethnique ;
                                                </li>
                                                <li>
                                                        Harceler les autres Utilisateurs ;
                                                </li>
                                                <li>
                                                        Promouvoir ou encourager toute activité ou entreprise criminelle ;
                                                </li>
                                                <li>
                                                        Demander et/ou communiquer des mots de passe et/ou des informations personnelles à des fins commerciales ou illégales ;
                                                </li>
                                                <li>
                                                        Transmettre des chaînes d'emails, des emails massifs non sollicités, des messages instantanés, des messages publicitaires et des spams ;
                                                </li>
                                                <li>
                                                        Contenir de la publicité et/ou une sollicitation afin de proposer des produits et/ou services à vendre via le Site ;
                                                </li>
                                                <li>
                                                        Contenir des adresses ou des liens Internet transférant vers un site Web externe dont le contenu enfreint toute loi et/ou réglementation applicable, enfreint les droits de tiers ou enfreint les Conditions.
                                                </li>
                                        </ul>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit s'engage à ne pas utiliser de système automatique tel que des scripts dans le but d'ajouter des Utilisateurs à son compte Utilisateur et/ou d'envoyer des commentaires ou des messages.
                                        </p>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit est seul responsable de la conservation et de la confidentialité de son mot de passe et s'engage à ne communiquer, à aucun moment, son mot de passe à un autre utilisateur ni à utiliser le compte, le nom ou le mot de passe d'un autre utilisateur.
                                        </p>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit certifie l'exactitude des informations fournies à COLORFOL lors de son inscription et s'engage à notifier toute modification.
                                        </p>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit reconnaît que COLORFOL a le droit, dans le cas où son utilisation du Site ou de l'Application violerait les CGU et plus largement le droit des tiers, les lois et réglementations en vigueur, de procéder immédiatement et sans préavis, à la suspension du Service Colorfol Gratuit et/ou au blocage de son accès au Service Colorfol Gratuit.
                                        </p>
                                        <p>
                                                COLORFOL a le droit, dans le cas où l'Utilisateur Colorfol Gratuit violerait la législation ou porterait atteinte aux droits de tiers, de fournir à la demande de toute autorité légitime (tribunaux, autorités administratives et forces de l'ordre), toute information permettant ou facilitant l’ identification du délinquant.
                                        </p>
                                        <h2>Article 7 – Exclusion de garanties</h2>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit déclare être informé des limites et limitations du réseau Internet.
                                        </p>
                                        <p>
                                                En conséquence, COLORFOL décline toute responsabilité quant à tout dysfonctionnement de l'accès au Service Colorfol Gratuit, de la vitesse d'ouverture et de consultation des pages du Site et/ou de l'Application, de la vitesse d'écoute des titres, de l'inaccessibilité temporaire ou définitive du Service Colorfol Gratuit, et l'utilisation frauduleuse par des tiers des informations fournies sur le Site ou l'Application.
                                        </p>
                                        <p>
                                                Il appartient donc à l'Utilisateur Colorfol Gratuit de protéger son équipement notamment contre toute forme d'intrusion et/ou de contamination virale et dont COLORFOL ne pourra jamais être tenu pour responsable. COLORFOL ne pourra jamais être tenu responsable de tout dysfonctionnement ou dommage causé au matériel de l'Utilisateur Colorfol Gratuit.
                                        </p>
                                        <p>
                                                Plus largement, COLORFOL décline toute responsabilité si un manquement à l'une de ses obligations résulte de faits naturels, de forces ou de causes échappant à son contrôle raisonnable, y compris, sans s'y limiter, des pannes d'Internet, des pannes d'équipements informatiques, des pannes d'équipements de télécommunication, d'autres pannes d'équipements, pannes d'électricité, grèves, conflits du travail, émeutes, insurrections, troubles civils, pénuries de main-d'œuvre ou de matériaux, incendies, inondations, tempêtes, explosions, catastrophes naturelles, guerres, actions gouvernementales, ordonnances de cours ou tribunaux nationaux ou étrangers, non- des prestations de tiers, ou perte ou variation de chaleur, de lumière, de climatisation et plus largement tout événement imparable et imprévu qui empêcherait la bonne exécution des commandes.
                                        </p>
                                        <p>
                                                COLORFOL décline toute responsabilité dans le cas où le Service Colorfol Gratuit s'avérerait incompatible avec certains équipements et/ou fonctionnalités de l'équipement de l'Utilisateur Colorfol Gratuit.
                                        </p>
                                        <p>
                                                Enfin, l'Utilisateur Colorfol Gratuit est seul responsable de son utilisation du Service Colorfol Gratuit et ne saurait tenir COLORFOL responsable de toute réclamation et/ou procédure engagée à son encontre à ce titre. L'Utilisateur Colorfol Gratuit prendra en charge toute réclamation, plainte ou objection et plus largement toute procédure intentée contre COLORFOL par un tiers en relation avec l'utilisation par l'Utilisateur Colorfol Gratuit du Service Colorfol Gratuit.
                                        </p>
                                        <h2>Article 8 – Propriété intellectuelle </h2>
                                        <p>
                                                La structure générale du Site, de l'Application, du Service Gratuit Colorfol et de tous les éléments le composant (tels que mais sans s'y limiter les logos, noms de domaine, titres ou vidéos, et les éléments associés tels que les photos, images, textes et biographie) des auteurs, artistes et/ou tout ayant droit sur les titres ou vidéos mais également les visuels présents sur les packagings) sont la propriété exclusive de COLORFOL et/ou de ses concédants de licence (notamment les producteurs de disques et vidéos, les maisons de disques, société d'auteurs) .
                                        </p>
                                        <p>
                                                Ces éléments sont protégés par les lois sur la propriété intellectuelle et autres, et notamment le droit d'auteur. Vous ne pouvez utiliser ces éléments que dans le but d'utiliser le Service Gratuit Colorfol, dans le respect des CGU.
                                        </p>
                                        <p>
                                                Toute représentation totale ou partielle du Site, de l'Application, du Service Gratuit Colorfol et/ou des éléments les composant (tels que décrits ci-dessus) par quelque procédé que ce soit sans l'accord exprès de COLORFOL est donc interdite et serait considérée comme une contrefaçon.
                                        </p>
                                        <p>
                                                Tout lien hypertexte renvoyant vers le Site et utilisant la technique du « framing » (technique de programmation offrant la possibilité de diviser les fenêtres du navigateur en différents frames indépendants afin d'afficher le contenu d'un site internet externe) ou de « in-line linking » (programmation pour faire faire disparaître dans une page web un élément extrait d'un autre site, ce qui économise de l'espace de stockage sur le disque dur où est hébergé le Site et permet de cacher à un utilisateur non averti le contenu initial dont fait partie l'élément) est strictement interdit. Toute utilisation artificielle d'un compte Colorfol (cette utilisation visant par exemple à augmenter artificiellement ou anormalement la lecture de certains morceaux de musique spécifiques), via des processus automatisés tels que des robots et des scripts ou via tout autre moyen, est strictement interdite.
                                        </p>
                                        <p>
                                                Les Enregistrements du Service Gratuit Colorfol sont des fichiers numériques protégés par le droit d'auteur national et international et les droits voisins. Leur écoute ne peut donc se faire que dans un cadre privé ou familial. Toute utilisation dans un but non privé exposera l'Utilisateur Colorfol Gratuit à des poursuites civiles et/ou pénales. Toute autre utilisation des Enregistrements est strictement interdite et plus particulièrement tout téléchargement ou tentative de téléchargement, tout transfert ou tentative de transfert permanent ou temporaire sur le disque dur d'un ordinateur ou de tout autre appareil (lecteurs de musique notamment), toute gravure ou tentative de graver un CD ou tout autre support est expressément interdit. Toute revente, échange ou location de ces fichiers est strictement interdite.
                                        </p>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit reconnaît que les Enregistrements mis à sa disposition au sein du Site et de l'Application sont protégés par des mesures techniques de protection mises en place par COLORFOL afin d'empêcher ou de limiter, selon la technologie proposée, l'utilisation non autorisée des Enregistrements.
                                        </p>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit s'engage à ne contourner, par aucun moyen, ces mesures techniques de protection, dans le but de télécharger ces fichiers et de permettre leur stockage au sein de l'unité de stockage de son terminal de réception quel qu'il soit (ordinateur, téléphone portable, lecteur de musique). et autre lecteur de disque musical portable, etc.).
                                        </p>
                                        <h2>Article 9 – Confidentialité </h2>
                                        <p>
                                                L’utilisateur reconnait et accepte que, dans le cadre des négociations et des transactions commerciales avec la Société, il aura la possibilité de prendre connaissance de certaines informations autrement confidentielles liées aux activités et aux pratiques commerciales de la COLORFOL. Sauf dans la mesure où ces informations sont par ailleurs généralement accessibles à des tiers ou doivent être divulguées par application de la loi, l’utilisateur accepte de garder ces informations confidentielles. Vous acceptez expressément que la Société ait le droit de fournir à des tiers des informations relatives aux ventes des Enregistrements en vertu des présentes, de regrouper ces informations dans des tableaux et autres documents d’information comparative, et de les diffuser de quelque manière que ce soit.
                                        </p>
                                        <h2>Article 10 – Suspension et/ou résiliation initiée par COLORFOL ou par un Utilisateur Colorfol Gratuit</h2>
                                        <p>
                                                Sans préjudice des dommages et intérêts que COLORFOL pourrait demander, COLORFOL a le droit de suspendre l'accès au Service Colorfol Gratuit d'un Utilisateur Colorfol Gratuit et/ou de résilier, sans préavis ni indemnité, son abonnement au Service Colorfol Gratuit dans les cas où l'Utilisateur Colorfol Gratuit ne respecte pas les CGU et notamment :
                                        </p>
                                        <ul>
                                                <li>
                                                        Enfreint les droits de propriété intellectuelle de COLORFOL ou de ses concédants de licence ;
                                                </li>
                                                <li>
                                                        Contourne ou tente de contourner les mesures techniques de protection mises en place par COLORFOL ;
                                                </li>
                                                <li>
                                                        Utilise plusieurs connexions simultanées avec le même compte ou tente de le faire ;
                                                </li>
                                                <li>
                                                        Fournit de fausses informations lors de sa souscription au Service Gratuit Colorfol.
                                                </li>
                                                <li>
                                                        Mène des activités préjudiciables aux intérêts commerciaux de COLORFOL.
                                                </li>
                                        </ul>
                                        <p>
                                                Les Utilisateurs Colorfol Gratuit peuvent à tout moment supprimer leur compte du Site via « Mon compte ».
                                        </p>
                                        <h2>Article 11 – Modifications des Conditions Générales d’Utilisation </h2>
                                        <p>
                                                COLORFOL a le droit de modifier à sa seule discrétion les présentes CGU. COLORFOL informera l'Utilisateur Colorfol Gratuit, par courrier électronique à l'adresse e-mail indiquée sur le compte de l'Utilisateur Colorfol Gratuit, de toute modification des CGU au moins 1 (un) mois avant leur entrée en vigueur. Dans le cas où l'Utilisateur Colorfol Gratuit n'accepterait pas ces modifications, il sera libre de supprimer son compte sur le Site comme mentionné à l'article 10 ci-dessus. Sauf si l'Utilisateur Colorfol Gratuit supprime son compte, les CGU mises à jour s'appliqueront à compter d'un (1) mois après réception de l'avis de modification des CGU.
                                        </p>
                                        <h2>Article 12 – Service Clientèle </h2>
                                        <p>
                                                L'Utilisateur Colorfol Gratuit peut contacter COLORFOL via le Site en cliquant sur la rubrique « Contactez-nous » ou en envoyant un mail à l'adresse contact.cm@colorfol.com
                                        </p>
                                        <p>
                                                Le Support Client fera des efforts raisonnables pour répondre à ces demandes dans un délai raisonnable mais ne peut donner aucune garantie d'aucune sorte que ces demandes recevront une réponse dans un délai correspondant aux attentes de l'Utilisateur Colorfol Gratuit, ou que la réponse sera entièrement satisfaisant pour cet utilisateur gratuit de Colorfol.
                                        </p>
                                        <h2>Article 13 – Nullité d'une clause  </h2>
                                        <p>
                                                Dans le cas où l'une des clauses des CGU serait déclarée nulle ou annulable pour quelque raison que ce soit, les autres clauses resteront applicables sans aucune modification.
                                        </p>
                                        <h2>Article 14 – Juridiction – Réclamations et litiges</h2>
                                        <p>
                                                Les présentes Conditions sont régies par les lois en vigueur en République du Cameroun.
                                        </p>
                                        <p>
                                                En cas de litige, les parties tenteront de parvenir à un accord à l'amiable avant toute procédure judiciaire. A défaut d'accord amiable dans un délai de trente jours, toute contestation relative à la validité, à l'interprétation et/ou à l'exécution des CGU devra être portée devant les Tribunaux de la ville de Douala.
                                        </p>
                                </div>
                        </div>
                </div>
        );
}

export default CGU;