import translate from "../i18n/translate";
export const freemium = [
    {
        name: translate("fpc_sii"),
       details: [
          {
            opt: translate("fpri")
          },
          {
              opt: translate("twpri")
          },
          {
              opt: translate("nipri")
          },
          {
              opt: translate("sepri")
          },
          {
              opt: translate("hepri")
          },
          
          ],
      },
    ]