import translate from '../../i18n/translate'
import '../../App.css';
import styles from '../styles/event.module.css'
import pm2 from '../../assets/img/demo.gif'
import wifi from "../../assets/new_images/wifi.svg";
import even from "../../assets/new_images/even.svg";
import cash from "../../assets/new_images/cash.svg";
import music from "../../assets/new_images/music.svg";
import arrow_down from "../../assets/new_images/arrow_down.svg";
import light_from_scy from "../../assets/new_images/light_from_scy.png";
import noise from "../../assets/new_images/noise.svg";
import blurright from "../../assets/new_images/blurright.svg"
import CurvedRender from "../../assets/new_images/CurvedRender.png"
import point from "../../assets/new_images/point.png"
import { useState } from 'react';
import Popup from './Popup';

const EventContainer = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className={`premium-cta ${styles.eventContainer}`} id='eg'>
      {/* <div className="fond3"></div>
      <div className="fond5"></div>
      <div className="fond4"></div> */}
      <div className={`why-head1 ${styles.whyHeader}`}>
        <span className='why-title1'>{translate("colprem1")}<span
          className='why-title2'>{translate("colprem2")}</span>
        </span>
      </div>
      <div className="premiumpart">
        <div className="left">
          <div className={`why-head1 ${styles.whyHeader}`}>
            <h3 className='hero-subtitle3'>{translate("upcol")}</h3>
          </div>
          <div className="carous2">
            <div className="carous-elt">
              <div className="subimg">
                <img className="subimgp" src={wifi} />
              </div>
              <span className="subtil">{translate("offplay")}</span>
              <span className="subsubtil">{translate("savelis")}</span>
            </div>
            <div className="carous-elt">
              <div className="subimg">
                <img className="subimgp" src={even} />
              </div>
              <span className="subtil">{translate("adfre")}</span>
              <span className="subsubtil">{translate("enjomu")}</span>
            </div>
            <div className="carous-elt">
              <div className="subimg">
                <img className="subimgp" src={music} />
              </div>
              <span className="subtil">{translate("playevr")}</span>
              <span className="subsubtil">{translate("lispc")}</span>
            </div>
            <div className="carous-elt">
              <div className="subimg">
                <img className="subimgp" src={cash} />
              </div>
              <span className="subtil">{translate("payyr")}</span>
              <span className="subsubtil">{translate("paymob")}</span>
            </div>
          </div>
          <div className="carous3">
            <button onClick={handleButtonClick} className="btn-disc3"><span className="btntext2">{translate("getprem")}</span><img className="imgbtn"
              src={arrow_down} />
            </button>
            {showPopup && (
              <Popup
                message={translate("cooming")}
                onClose={handlePopupClose}
              />
            )}
          </div>
        </div>
        <div className="right">
          <div className="app-preview">
            <img src={CurvedRender} alt="Aperçu de l'application Colorfol" />
          </div>
        </div>
      </div>
      <div>
        <div className="pointImg">
          <img className="point" src={point} />
        </div>
      </div>
      <div className="background-container">
        <div className={`why-head ${styles.whyHeader}`}>
          <span className='why-title3'>{translate("knowmore")}</span>
        </div>
        <div className={`why-head ${styles.whyHeader}`}>
          <h3 className='hero-subtitle6'>{translate("conta")}</h3>
          <a href="https://wa.me/+33605875704" target='_blank' rel="noreferrer">
            <button className="btn-disc2"><span className="btntext3">{translate("contactus")}</span></button> </a>
        </div>
        <div className={`why-head ${styles.whyHeader}`}>
          <h3 className='hero-subtitle6'>{translate(" ")}</h3>
        </div>

      </div>
    </div>
  )
}
export default EventContainer
