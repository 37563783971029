import React from "react";
import "../App.css";

function IconButton({ icon, title, onClick, link }) {
  return (
    <a href={link} style={{ textDecoration: "none" }}>
      <span className="hero-cta">
        <img
          src={icon}
          width="30"
          height="30"
          alt="cta-icon"
          className="btn-icon"
          onClick={onClick}
        />
        {title}
      </span>
    </a>
  );
}

export default IconButton;
