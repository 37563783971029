import translate from '../../i18n/translate'
import '../../App.css';
import styles from '../styles/premium.module.css'
import phone1 from '../../assets/new_images/phone1.svg'
import pp1 from '../../assets/new_images/pp1.svg'
import pp2 from '../../assets/new_images/pp2.svg'
import pp3 from '../../assets/new_images/pp3.svg'
import back5 from '../../assets/new_images/back5.svg'
import back3 from "../../assets/new_images/back4.svg";
import back2 from "../../assets/new_images/back2.svg";
import back4 from "../../assets/new_images/back3.svg";
import back1 from "../../assets/new_images/back1.png";

const PremiumContainer = () => {

    return (
        <div className={`premium-cta ${styles.premiumContainer}`}>
            <div className={`why-head ${styles.whyHeader}`}>
                <span className='why-title'>{translate("supp")}</span>
            </div>

            <div className={`why-head ${styles.whyHeader}`}>
              <h3 className='hero-subtitle2'>{translate("chall")}</h3>
            </div>
            <img src={back5} className='back5'/>
            <div className="carous">
                <div className="carous-elt1">
                    <img className="carousimg" src={pp1}/>
                    <span className="carousspan">Sandrine Nanga</span>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp2}/>
                    <span className="carousspan">Ko-C</span>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp3}/>
                    <span className="carousspan">Dashor</span>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp1}/>
                    <span className="carousspan">Sandrine Nanga</span>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp2}/>
                    <span className="carousspan">Ko-C</span>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp3}/>
                    <span className="carousspan">Dashor</span>
                </div>
                <div>
                    <img className="carousimgprime" src={phone1}/>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp3}/>
                    <span className="carousspan">Mimie</span>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp2}/>
                    <span className="carousspan">H3riQ</span>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp1}/>
                    <span className="carousspan">Kill B</span>
                </div>

                <div className="carous-elt1">
                    <img className="carousimg" src={pp3}/>
                    <span className="carousspan">Mimie</span>
                </div>
                <div className="carous-elt1">
                    <img className="carousimg" src={pp2}/>
                    <span className="carousspan">H3riQ</span>
                </div>
            </div>
        </div>
    )
}

export default PremiumContainer